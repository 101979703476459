<template>
  <v-card outlined>
    <multi-features-box :xl-items=2 :md-items=2 :features="tapsComponents" />
  </v-card>
</template>

<script>
import MultiFeaturesBox from "@/components/products/common/MultiFeaturesBox";
export default {
  components: {MultiFeaturesBox},
  data() {
    return {
      tapsComponents: [
        {
          name: "Tablica ostrzegawcza",
          img: require("../../../assets/taps/k 071.png"),
          img_position: 'left',

          short_description: "",
          description: "Stacjonarny układ ostrzegający jest urządzeniem, które może przybrać dowolną formę (np. tablicy T-0 instalowanej pod znakami drogowymi). Element ten odpowiada za emisję ostrzeżenia po odebraniu sygnału od systemu (np. wskutek aktywacji detektora ruchu lub informacji z aplikacji mobilnej)."
        },
        {
          name: "Detektor ruchu",
          img: require("../../../assets/taps/k_121.png"),
          img_position: 'left',

          short_description: "",
          description: "Sensor informujący system o obecności, kierunku jazdy oraz prędkości niechronionego uczestnika ruchu. Komponent instalowany jest na samonośnej konstrukcji lub do stałego elementu infrastruktury."
        },
        {
          name: "Aplikacja mobilna dla uczestnika ruchu",
          img: require("../../../assets/taps/k_131.png"),
          img_height: 180,
          img_position: 'left',
          short_description: "",
          description: "Oprogramowanie dla systemów Android oraz iOS pracuje zarówno w połączeniu z rowerowym\n" +
              "systemem oświetleniowym jak i ze stałymi elementami infrastruktury. Jego główne\n" +
              "cechy to: <ul>" +
              "<li>Komunikacja z systemem - stałe przesyłanie informacji pozwalających na emisję sygnału\n" +
              "ostrzegawczego oraz na wnioskowanie dotyczące bezpieczeństwa danego obszaru.</li>" +
              "<li>Sterowanie zestawem oświetleniowym - programowanie trybu działania, np. w\n" +
              "zależności od pory dnia lub rodzaju zabudowy.</li>" +
              "<li>Emisja sygnałów ostrzegających za pomocą sygnału oświetleniowego.</li>" +
              "</ul>"
        },
        {
          name: "Rowerowy system oświetleniowy",
          img: require("../../../assets/taps/taps_light_004.png"),
          img_height: 180,
          img_position: 'left',

          short_description: "",
          description: "Zestaw świateł rowerowych wyposażony w moduł komunikacji bezprzewodowej BLE " +
              "pozwala na reaktywne sterowanie rodzajem i mocą oświetlenia. Główne cechy zestawu to: " +
              "<ul>" +
              "<li>Zmiana parametrów oświetlenia z możliwością regulacji każdego z modułów LED\n" +
              "osobno.</li>" +
              "<li>Podgląd poziomu baterii i zarządzanie oszczędzaniem energii.</li>" +
              "<li>Automatyczne uruchamianie oświetlenia po rozpoczęciu jazdy i wyłączenie po\n" +
              "określonym czasie od zatrzymania.</li>" +
              "<li>Możliwość zasilania akumulatorowego lub zintegrowania z zasilaniem zewnętrznym (np.\n" +
              "rowerów elektrycznych).</li>" +
              "<li>Moduły LED przeznaczone do instalacji z przodu, z tyłu oraz jako oświetlenie boczne.</li>" +
              "</ul>"
        },

      ],
    }
  },
}
</script>
