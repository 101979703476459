<template>
  <v-container fluid v-if="features.length" style="">
    <v-row :justify="justifyCenter ? 'center' : 'start' " align="start">
      <v-col v-for="(component, index) in features" :key="index" :xl="12 / xlItems" sm="12" xs="12" cols="12" :md="12 / mdItems">
        <v-hover v-slot="{ hover }" :close-delay="closeDelay" :open-delay="openDelay">
          <v-sheet :class="hover ? 'item-hovered' : ''" :elevation="hover ? 3 : 0" :color="hover ? boxBackground : 'transparent'">
            <v-progress-linear v-if="showProgress" background-opacity="0" :value="hover ? 100 : 15"></v-progress-linear>


            <v-container v-if="component.img_position">
              <v-row>
                <v-col md="4" sm="4">
                  <v-img

                      class="mt-5"
                      contain
                      :height="component.img_height"
                      v-if="component.img"
                      :src="component.img"
                  ></v-img>
                </v-col>
                <v-col>
                  <v-card-title style="word-break: normal;" class="mb-2 text--primary" :class="{'text-left' : textLeft}">
                    {{ component[nameColumn] }}</v-card-title>
                  <v-card-subtitle  :key="index" class="text-left" :class="!hover ? 'grey--text text--darken-2' : ''"
                                    v-html="component[descColumn]"></v-card-subtitle>
                </v-col>
              </v-row>
            </v-container>

            <v-container v-else>

              <v-card-title style="word-break: normal;" class="mb-2 text--primary" :class="{'text-left' : textLeft}">
                {{ component[nameColumn] }}</v-card-title>

              <v-card-subtitle  :key="index" class="text-left" :class="!hover ? 'grey--text text--darken-2' : ''"
                                v-html="component[descColumn]"></v-card-subtitle>
            </v-container>


          </v-sheet>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    features: {
      type: Array,
      default: () => []
    },

    xlItems: {
      type: Number,
      default: () => 3
    },

    mdItems: {
      type: Number,
      default: () => 3
    },


    showProgress: {
      type: Boolean,
      default: () => true
    },

    nameColumn: {
      type: String,
      default: () => 'name'
    },
    descColumn: {
      type: String,
      default: () => 'description'
    },
    justifyCenter: {
      type: Boolean,
      default: () => false
    },
    closeDelay: {
      type: Number,
      default: () => 500
    },
    openDelay: {
      type: Number,
      default: () => 0
    },

    textLeft: {
      type: Boolean,
      default: () => true
    },

    boxBackground: {
      type: String,
      default: () => '#fbfbfb'
    }
  },

}


</script>
