<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row justify="center">
          <v-slide-y-transition>
            <v-sheet elevation="0" rounded outlined>
              <video-player
                  v-show="show"
                  ref="videoPlayer"
                  :options="playerOptions"
                  @play="onPlayerPlay"
                  @pause="onPlayerPause"
              />
            </v-sheet>
          </v-slide-y-transition>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from '../config'
export default {

  data() {
    return {
      show: true,
      visiblePlayer: false,
      autoStart: false,
      isPlaying: false,
    }
  },

  methods: {
    play() {
      this.show = true
      this.$refs.videoPlayer.player.play();
    },
    pause() {

      this.show = false
      this.$refs.videoPlayer.player.pause();

    },
    onPlayerPlay() {
      this.isPlaying = true
    },

    onPlayerPause() {
      this.isPlaying = false
    }
  },

  computed: {

    videoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return window.innerWidth
        case 'sm': return window.innerWidth
        case 'md': return 500
        case 'lg': return 900
        case 'xl': return 1200
      }
      return 800;
    },


    playerOptions() {
      return {
        width: this.videoWidth,
        autoplay: this.autoStart,
        muted: true,
        loop: true,
        language: 'en',
        // playbackRates: [0.7, 1.0, 1.5, 2.0],
        controls: true,
        controlBar: {
          playToggle: true,
          captionsButton: false,
          chaptersButton: false,
          subtitlesButton: false,
          remainingTimeDisplay: true,
          volumePanel: true,
          pictureInPictureToggle: true,
          progressControl: {
            seekBar: true
          },
          fullscreenToggle: true,
          playbackRateMenuButton: false
        },
        sources: [{
          type: "video/mp4",
          src: config.TAPS_VIDEO_SRC,
        }],
        poster: require('../assets/taps/urbanitus_1.png'),
      }
    }
  },
}
</script>


<style>


.video-player {

}

.video-js .vjs-big-play-button
{
  /*display: none;*/
  margin-left: auto;
  margin-right: auto;
  top: 45%;
  left: 0;
  right: 0;
  text-align: center;
}
.vjs-poster {
  background-color: #fff;
}

</style>
