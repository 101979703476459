<template>
  <v-sheet elevation="0" class="">
    <v-container fluid>
      <v-sheet  v-for="(content, index) in contents" :key="index">
        <v-divider />

        <v-row xl="12" class="hidden-sm-and-down">
          <v-col>
            <v-parallax
                light
                :src="require('../../../assets/taps/k_011.png')"
            >
              <v-row
                  align="center"
                  justify="center"
              >
                <v-col
                    class="text-center"
                    cols="8"
                >
                  <div
                      :class="content.title_level ? 'text-h'+content.title_level : 'text-h2'"
                      class="primary--text text-center font-weight-medium">
                    {{content.title}}
                  </div>
                  <div style="background-color: rgba(255,255,255,0.3)" class="my-4 primary--text"  :class="content.icon ? '' : ' text-center'" v-html="content.subtitle">
                  </div>

                  <div class="text-center">
                    <contact-popup :topic="'Miejski System Ochrony Uczestników Ruchu - Urbanitus'" />
                  </div>
                </v-col>
              </v-row>
            </v-parallax>
          </v-col>
        </v-row>

      </v-sheet>

    </v-container>
    <v-divider />
    <TextHeading :level=4 class="pa-8 primary--text text-uppercase">{{title}}</TextHeading>
    <v-container :fluid="!this.$vuetify.breakpoint.xlOnly">
      <v-row>
        <v-col md="8" sm="12" cols="12" xs="12">
          <taps-admin-components />
        </v-col>
        <v-col xs="12" lg="4">
          <v-card elevation="8">
            <v-card-title style="word-break: normal;" class="pb-0 text-left">{{serverFeatures.name}}</v-card-title>
            <v-card-text>
              <v-img
                  :src="require('../../../assets/taps/k_090.png')"
              ></v-img>

              <div class="text-left mb-4">
                {{serverFeatures.subtitle}}
              </div>
              <v-alert
                  dense
                  icon="mdi-check"
                  colored-border
                  color="primary"
                  elevation="1"
                  v-for="(feature, index) in serverFeatures.mainFeatures" :key="index" class="text-left">
                <span class="text-body-2">{{feature.subtitle}}</span>
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>


    <v-divider />
    <TextHeading :level=4 class="pa-8 primary--text text-uppercase">Możliwości zastosowania i warianty konfiguracji</TextHeading>
    <multi-features-box
        :features="tapsConfigurations"
    />

  </v-sheet>
</template>

<script>
import MultiFeaturesBox from "@/components/products/common/MultiFeaturesBox";
import TextHeading from "@/modules/TextHeading";
import ContactPopup from "@/components/ContactPopup";
import TapsAdminComponents from "@/components/products/taps/TapsAdminComponents";
export default {
  components: {TapsAdminComponents, ContactPopup, TextHeading, MultiFeaturesBox},
  data() {
    return {

      title: 'Komponenty systemu Urbanitus',
      tapsConfigurations: [

        {
          name: "Tablica ostrzegawcza wyzwalana detektorem ruchu",
          description: "Detektor ruchu wykrywający przejazd w połączeniu z tablicą ostrzegawczą  - detekcja niechronionego uczestnika ruchu w pobliżu skrzyżowania spowoduje aktywację układu ostrzegającego, umożliwiającego sygnalizację obecności np. rowerzystów na ścieżce rowerowej."
        },
        {
          name: "Tablica ostrzegawcza aktywowana aplikacją mobilną",
          description: "Dedykowana aplikacja mobilna umożliwia przesyłanie prezycyjnych informacji związanych ze znalezieniem się użytkownika w strefie ryzyka wystąpienia zdarzenia drogowego. Aktywacja układu ostrzegającego jest możliwa bez konieczności instalacji detektorów ruchu."
        },
        {
          name: "Wolnostojący detektor analizujący ruch",
          description: "Komponent instalowany jako rozszerzenie miejskiej infrastruktury umożliwia akwizycję i przetwarzanie danych związanych z ilością przejazdów, średnimi i maksymalnymi prędkościami oraz kierunkiem."
        }

      ],

      serverFeatures: {
        name: "Centrum monitoringu i analiz",
        subtitle: "Centrum monitoringu i analiz to aplikacja internetowa udostępniana administratorom systemu. Jej główne cechy to: ",
        mainFeatures: [
          {
            title: "- Analiza i pozyskiwanie danych dot. ruchu",
            subtitle: "Analiza ruchu w miejscach instalacji detektorów ruchu lub na podstawie danych udostępnionych przez uczestników korzystających z aplikacji mobilnej. Analiza obejmuje między innymi weryfikację ilości przejazdów, prędkości, kierunku, czy stylu jazdy."
          },
          {
            subtitle: "Możliwość zmiany trybu działania tablicy ostrzegawczej (tj. zmiana sposobu emisji ostrzeżenia).",
          },
          {
            subtitle: "Diagnostyka działania wszystkich komponentów systemu.",
          },
          {
            subtitle: "Podgląd danych statystycznych detektorów ruchu (stacjonarnych punktów pomiarowych) oraz niechronionych uczestników ruchu."
          }
        ]
      },

      contents: [
        {
          title: "Zwiększ bezpieczeństwo niechronionych uczestników ruchu w Twoim regionie",
          title_level: 2,
          subtitle: "Celem systemu <b>Urbanitus</b> jest poprawa bezpieczeństwa i komfortu uczestników ruchu drogowego, należących do grupy tzw. niechronionych uczestników ruchu (pieszych, osób\n" +
              "przemieszczających się rowerem, hulajnogą, deskorolką itp.).",

        },
/*        {
          title: "Możliwości zastosowań",
          title_level: 6,
          subtitle: "",
/!*          icon: {
            src: 'mdi-bike',
            size: 200,
            color: 'primary',
            position: 'right'
          }*!/
        }*/
      ]
    }
  },
}
</script>
