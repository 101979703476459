<template>
  <v-sheet color="white" elevation="0" class="mt-6">
    <product-page v-if="false" :title="utpLightFeatures[0].name"></product-page>
    <v-container>
      <v-row justify="center">

        <v-col cols="12" md="10" xl="8">
          <v-card elevation="1">
            <v-sheet v-for="(feature, index) in utpLightFeatures" :key="index">

              <v-container>
                <v-row>
                  <v-col sm="12" md="3" lg="2">
                    <v-img
                        v-if="feature.img"
                        width="100"
                        class="ma-10"
                        :src="feature.img"
                    ></v-img>
                  </v-col>
                  <v-col>

                    <v-card-title class="pb-0">{{feature.name}}</v-card-title>
                    <v-card-text>
                      <div class="text-left mb-4">
                        {{feature.subtitle}}
                      </div>

                      <transition-group appear name="list">
                        <v-alert
                            dense
                            icon="mdi-check"
                            colored-border
                            color="primary"
                            elevation="1"
                            v-for="(entity, index) in feature.mainFeatures" v-bind:key="index" class="text-left">
                          <span class="text-body-2 list-item">{{entity.subtitle}}</span>
                        </v-alert>
                      </transition-group>

                    </v-card-text>
                  </v-col>
                </v-row>
              </v-container>


            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-sheet>
</template>
<script>
import ProductPage from "@/components/products/ProductPage";
export default {
  components: {ProductPage},
  data() {
    return {
      utpLightFeatures: [
        {
          name: "Rowerowy system oświetleniowy",
          img: require('../../../assets/taps/k_051.png'),
          subtitle: "Zestaw świateł rowerowych wyposażony w moduł komunikacji bezprzewodowej BLE pozawala\n" +
              "na reaktywne sterowanie rodzajem i mocą oświetlenia. Główne cechy zestawu to:",
          mainFeatures: [
            {
              subtitle: "Zmiana parametrów oświetlenia z możliwością regulacji każdego z modułów LED\n" +
                  "osobno."
            },
            {
              subtitle: "Podgląd poziomu baterii i zarządzanie oszczędzaniem energii.",
            },
            {
              subtitle: "Automatyczne uruchamianie oświetlenia po rozpoczęciu jazdy i wyłączenie po\n" +
                  "określonym czasie od zatrzymania.",
            },
            {
              subtitle: "Możliwość zasilania akumulatorowego lub zintegrowania z zasilaniem zewnętrznym (np.\n" +
                  "rowerów elektrycznych)."
            },
            {
              subtitle: "Moduły LED przeznaczone do instalacji z przodu, z tyłu oraz jako oświetlenie boczne."
            }
          ]
        },
        {
          name: "Aplikacja mobilna dla niechronionego uczestnika ruchu",
          img: require('../../../assets/taps/k_131.png'),

          subtitle: "Oprogramowanie dedykowane urządzeniom mobilnym opartym o system Android oraz iOS.",
          mainFeatures: [
            {
              subtitle: "Komunikacja z systemem - przesyłanie informacji pozwalających na emisję sygnału\n" +
                  "ostrzegawczego oraz na wnioskowanie dotyczące bezpieczeństwa danego obszaru."
            },
            {
              subtitle: "Sterowanie zestawem oświetleniowym - programowanie trybu działania, np. w\n" +
                  "zależności od pory dnia lub rodzaju zabudowy."
            },
            {
              subtitle: "Emisja sygnałów ostrzegających za pomocą sygnału oświetleniowego."
            }
          ]
        }

      ]
    }
  },
}
</script>

<style>

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.flip-list-move {
  transition: transform 1s;
}

</style>
