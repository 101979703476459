<template>
<v-container fluid>

  <v-row justify="space-around" >
    <v-col md="3"  class="text-justify text-center" align-self="center">
      <v-img transition="scale-transition" class="mt-10"  contain :src="require('../../assets/urbanitus_logo.png')"></v-img>
    </v-col>
  </v-row>

  <v-row justify="space-around" >
    <div class="mb-6" style="font-style: italic" v-html="subtitle"></div>
  </v-row>

  <v-row>
    <video-player-wrapper />
  </v-row>

  <v-row justify="space-around" >
    <v-col md="12">
      <v-btn-toggle v-model="toggle_type" mandatory color="primary">
        <v-container>
          <v-row>
            <v-col>
              <v-btn :small="this.$vuetify.breakpoint.xsOnly" :value=0>
                Dla jednostek zarządzających regionem
              </v-btn>
            </v-col>
            <v-col>
              <v-btn :small="this.$vuetify.breakpoint.xsOnly" :value=1>
                Dla klientów indywidualnych
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-btn-toggle>
      <v-slide-y-transition mode="out-in">
        <taps-region-admin v-if="toggle_type === 0" />
        <taps-regular-user v-else />
      </v-slide-y-transition>
    </v-col>
  </v-row>

</v-container>
</template>

<script>
import TapsRegionAdmin from "@/components/products/taps/TapsRegionAdmin";
import TapsRegularUser from "@/components/products/taps/TapsRegularUser";
import VideoPlayerWrapper from "@/modules/VideoPlayerWrapper";
export default {
  components: {TapsRegularUser, TapsRegionAdmin, VideoPlayerWrapper},
  data() {
    return {
      showOverview: false,
      qmsConfiguratorOverlay: false,
      //TODO: missing translations or restful request
      title: 'Miejski System Ochrony Uczestników Ruchu "Urbanitus" ',
      subtitle: 'Miejski System Ochrony Uczestników Ruchu',
      toggle_type: 0
    }
  },
}
</script>
